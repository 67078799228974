import React from "react"
import ExhibitionList from "../components/ExhibitionList"
import Exhibition from "../components/Exhibition"
import Moment from "moment"
//import { isMobile } from "react-device-detect"

class ExhibitionControler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentExhibition: null,
      data: props,
    }
    this.handleClick = this.handleClick.bind(this)

    // Change exhibition component state

    this.exhibitionElement = React.createRef()
  }

  // Method to sort exhibitions to happening, upcoming and past
  sortExhibitions() {
    const data = this.state.data.data
    const lang = this.state.data.lang

    const allExhibitions = data.allWordpressWpExhibitions.nodes

    const exhibitions = allExhibitions.filter(
      ex => ex.polylang_current_lang.split("_")[0] === lang
    )

    const now = Moment()

    class ExhibitionGroup {
      constructor(title) {
        this.title = title
        this.exhibitions = []
      }
    }

    const exhibitionsSorted = []

    const happeningNow = new ExhibitionGroup("happening_now")
    const upcomingEvents = new ExhibitionGroup("upcoming_events")
    const pastEvents = new ExhibitionGroup("past_events")

    // Exhibition sorting
    exhibitions.forEach(el => {
      const startDate = el.acf.exhibition.start_date
      const endDate = el.acf.exhibition.end_date

      if (now.isBetween(startDate, endDate, "day", "[]")) {
        happeningNow.exhibitions.push(el)
      }
      if (now.isBefore(startDate, "day")) {
        upcomingEvents.exhibitions.push(el)
      }
      if (now.isAfter(endDate, "day")) {
        pastEvents.exhibitions.push(el)
      }
    })

    exhibitionsSorted.push(happeningNow, upcomingEvents, pastEvents)

    return exhibitionsSorted
  }

  // Change event info on event click
  handleClick(e) {
    const exSlug = e.target.closest(".list__group__item").dataset.slug
    const allExhibitions = this.state.data.data.allWordpressWpExhibitions.nodes
    const currentExhibition =
      allExhibitions[allExhibitions.findIndex(el => el.slug === exSlug)]

    this.setState({
      currentExhibition: currentExhibition,
      activeExhibition: true,
    })

    if (this.exhibitionElement.current !== null) {
      this.exhibitionElement.current.updateExhibition(currentExhibition)
    }
  }

  setInitialExhibition() {
    const exhibitions = this.sortExhibitions()
    for (let i = 0; i < exhibitions.length; i++) {
      const ex = exhibitions[i]
      if (ex.title === "happening_now" && ex.exhibitions.length > 0) {
        this.setState({
          currentExhibition: ex.exhibitions[0],
        })
        break
      } else if (ex.title === "upcoming_events" && ex.exhibitions.length > 0) {
        this.setState({
          currentExhibition: ex.exhibitions[0],
        })
        break
      } else if (ex.title === "past_events" && ex.exhibitions.length > 0) {
        this.setState({
          currentExhibition: ex.exhibitions[0],
        })
        break
      }
    }
  }

  // Set initial exhibition
  componentDidMount() {
    if (window.innerWidth > 1023) {
      this.setInitialExhibition()
    }
  }

  render() {
    const title = this.props.data.wordpressPage.title
    const translations = this.props.data.wordpressPage.acf
      .translations_exhibitions

    return (
      <div className="grid">
        <div className="row row--no-gutter">
          <div className="col lg-3-12 md-12-12">
            <div className="exhibitions__title">
              <h1 className="h2" dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          </div>
          <div className="col lg-4-12 md-5-12">
            <ExhibitionList
              lang={this.props.lang}
              exhibitionsSorted={this.sortExhibitions()}
              strings={translations}
              click={this.handleClick}
              exhibitionsRef={this.props.exhibitionsRef}
            />
          </div>
          <div className="col lg-5-12 md-7-12">
            {this.state.currentExhibition !== null && (
              <Exhibition
                ref={this.exhibitionElement}
                data={this.state.currentExhibition}
              />
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ExhibitionControler
