import React from "react"
import * as Animations from "../animations"
import { Transition } from "react-transition-group"
import gsap from "gsap"
import Scrollbar from "react-smooth-scrollbar"
import logoDarkMobile from "../images/mobile-logo-dark.svg"
import { isMobile } from "react-device-detect"
// const isMobile = true

class Exhibition extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: this.props.data,
      visible: true,
    }
  }

  setStateAsync(state) {
    return new Promise(resolve => {
      this.setState(state, resolve)
    })
  }

  async updateExhibition(data) {
    const delay = window.outerWidth < 769 ? 0 : 370

    await this.setStateAsync({ visible: false })

    setTimeout(async () => {
      await this.setStateAsync({ visible: true, data: data })
    }, delay)
  }

  async closeExhibition() {
    await this.setStateAsync({
      data: null,
      visible: false,
    })
  }

  animateExhibition() {
    gsap.to(".exhibition__content__part", 0.5, {
      y: 0,
      opacity: 1,
      stagger: 0.1,
    })
    if (window.outerWidth < 769) {
      this.toggleHeaderVisibility(false)
    }
  }

  toggleHeaderVisibility(toggle) {
    const visiblity = toggle ? "visible" : "hidden"
    gsap.to("header", 0.2, {
      visibility: visiblity,
    })
    gsap.to(".btn--burger", 0.2, {
      visibility: visiblity,
    })
  }

  componentDidMount() {
    // Initial active exhibition animation

    const delay = window.outerWidth < 801 ? 0 : 1500

    setTimeout(() => {
      this.animateExhibition()
    }, delay)

    window.addEventListener("orientationchange", () => {
      if (window.outerWidth > 799) {
        this.toggleHeaderVisibility(false)
      } else if (window.outerWidth < 801 && this.state.visible === true) {
        this.toggleHeaderVisibility(true)
      }
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible === false) {
      this.animateExhibition()
    }
    if (this.state.visible === false && isMobile) {
      this.toggleHeaderVisibility(true)
    }
  }

  render() {
    const visible = this.state.visible
    const data = this.state.data

    if (data !== null) {
      const exTitle = this.state.data.title
      const exContent = this.state.data.acf.exhibition
      const exImg = exContent.image_exhibition
        ? exContent.image_exhibition.localFile.childImageSharp.fluid.src
        : false

      return (
        <div id="exhibition">
          <button
            className="exhibition__close js-exhibition-close btn btn--x"
            onClick={e => {
              this.closeExhibition()
            }}
          ></button>
          <div className="exhibition__logo">
            <img src={logoDarkMobile} alt="logo" />
          </div>
          <div className="exhibition__bg" />
          <Scrollbar
            id="exhibition-scroll-container"
            className="exhibition__content-container"
            damping={0.08}
            alwaysShowTracks={true}
          >
            <div className="exhibition__content">
              <Transition
                timeout={1000}
                mountOnEnter={true}
                unmountOnExit={true}
                in={visible}
                onEnter={(node, done) => {
                  Animations.exhibitionAnimationIn(node, 0, done)
                }}
                onExit={(node, done) => {
                  Animations.exhibitionAnimationOut(node, 0, done)
                }}
              >
                <div className="exhibition__content__img exhibition__content__part">
                  {exImg && (
                    <img src={exImg} />
                  )}
                </div>
              </Transition>

              <Transition
                timeout={1000}
                mountOnEnter={true}
                unmountOnExit={true}
                in={visible}
                onEnter={(node, done) => {
                  Animations.exhibitionAnimationIn(node, 0.1, done)
                }}
                onExit={(node, done) => {
                  Animations.exhibitionAnimationOut(node, 0, done)
                }}
              >
                <h3
                  className="exhibition__content__part"
                  dangerouslySetInnerHTML={{ __html: exTitle }}
                />
              </Transition>
              {exContent.details && (
                <Transition
                  timeout={1000}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  in={visible}
                  onEnter={(node, done) => {
                    Animations.exhibitionAnimationIn(node, 0.2, done)
                  }}
                  onExit={(node, done) => {
                    Animations.exhibitionAnimationOut(node, 0, done)
                  }}
                >
                  <div className="exhibition__content__details exhibition__content__part">
                    <p
                      className="dotted"
                      dangerouslySetInnerHTML={{ __html: exContent.details }}
                    />
                  </div>
                </Transition>
              )}
              {exContent.description && (
                <Transition
                  timeout={1000}
                  mountOnEnter={true}
                  unmountOnExit={true}
                  in={visible}
                  onEnter={(node, done) => {
                    Animations.exhibitionAnimationIn(node, 0.3, done)
                  }}
                  onExit={(node, done) => {
                    Animations.exhibitionAnimationOut(node, 0, done)
                  }}
                >
                  <article
                    className="exhibition__content__part article--exhibition"
                    dangerouslySetInnerHTML={{ __html: exContent.description }}
                  />
                </Transition>
              )}
            </div>
          </Scrollbar>
        </div>
      )
    } else {
      return null
    }
  }
}

export default Exhibition
