import React from "react"
import * as Animations from "../animations"
import Scrollbar from "react-smooth-scrollbar"
import * as FakeScrollbar from "../fakeScrollbar"

class ExhibitionList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      strings: this.props.strings,
      exhibitionsSorted: this.props.exhibitionsSorted,
    }
  }

  handleClick = e => {
    this.props.click(e)
    Array.from(document.querySelectorAll(".list__group__item")).forEach(el => {
      el.classList.remove("active")
    })
    e.target.closest(".list__group__item").classList.add("active")
  }

  componentDidMount() {
    let delay = 0.65
    Array.from(document.querySelectorAll(".exhibitions__list__group")).forEach(
      el => {
        Animations.appearAnimation(el, delay)
        delay += 0.32
      }
    )
    // Add active class initially
    setTimeout(() => {
      document
        .querySelector(".exhibitions__list__group")
        .childNodes[1].classList.add("active")
    }, 1100)

    const { scrollbar } = this.scrollContainer

    scrollbar.addListener(status => {
      FakeScrollbar.moveScrollbar()
    })
  }

  render() {
    const strings = this.state.strings
    const exhibitionsSorted = this.state.exhibitionsSorted

    return (
      <Scrollbar
        id="exhibition-scroll-container"
        className="exhibitions__list"
        damping={0.08}
        alwaysShowTracks={true}
        ref={c => (this.scrollContainer = c)}
      >
        <div className="exhibitions__list-container">
          {exhibitionsSorted.map(group => {
            if (group.exhibitions.length > 0) {
              return (
                <div
                  key={group.title}
                  className="exhibitions__list__group appear"
                >
                  <h3 className="group__title" dangerouslySetInnerHTML={{__html: strings[group.title]}} />
                  {group.exhibitions.map(ex => {
                    const exContnet = ex.acf.exhibition

                    return (
                      <div
                        className="list__group__item"
                        key={ex.slug}
                        data-slug={ex.slug}
                        onClick={e => this.handleClick(e)}
                        onKeyDown={e => this.handleClick(e)}
                        role="button"
                        tabIndex="0"
                      >
                        <h4 className="smaller" dangerouslySetInnerHTML={{__html: ex.title}} />
                        <div className="group__item__info-container">
                          <p className="group__item__info">
                            {exContnet.details}
                          </p>
                          <div className="group__item__info__btn">
                            <div className="btn-container">
                              <span className="btn btn--arrow">
                                {strings.more}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="group__item__point point point--black">
                          <div className="point__line" />
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            } else {
              return undefined
            }
          })}
        </div>
      </Scrollbar>
    )
  }
}

export default ExhibitionList
