import React from "react"
import Layout from "../layouts/layout"
import Points from "../components/Points"
import ExhibitionControler from "../components/ExhibitionControler"
import { StaticQuery } from "gatsby"
import Div100vh from "react-div-100vh"

class TemplateExhibitions extends React.Component {
  constructor(props) {
    super(props)
    this.exhibtionsRef = React.createRef()
  }

  render() {
    return (
      <Layout
        footerless="true"
        lang={this.props.pageContext.lang}
        tpl={this.props.pageContext.templateGT}
        theme="white"
        intro={false}
      >
        <Div100vh id="exhibitions" ref={this.exhibtionsRef}>
          <ExhibitionControler
            data={this.props.data}
            lang={this.props.pageContext.lang}
            exhibitionsRef={this.exhibtionsRef}
          />
          <div className="exhibitions__overlay">
            <div className="grid">
              <div className="row row--no-gutter">
                <div className="col col--middle lg-4-12 lg-push-left-3-12">
                  <div className="exhibitions__overlay__fade exhibitions__overlay__fade--middle"></div>
                </div>
                <div className="col col--right lg-5-12">
                  <div className="exhibitions__overlay__fade exhibitions__overlay__fade--right"></div>
                </div>
              </div>
            </div>
          </div>
          {1 == 0 && <Points location="exhibitions" theme="dark" />}
        </Div100vh>
      </Layout>
    )
  }
}

export default props => (
  <StaticQuery
    query={graphql`
      query($id: Int) {
        wordpressPage(wordpress_id: { eq: $id }) {
          title
          acf {
            translations_exhibitions {
              happening_now
              upcoming_events
              past_events
              more
            }
          }
        }
        allWordpressWpExhibitions {
          nodes {
            title
            slug
            polylang_current_lang
            acf {
              exhibition {
                start_date
                end_date
                details
                description
                image_exhibition {
                  localFile {
                    childImageSharp {
                      fluid {
                        src
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={(data, pageContext) => (
      <TemplateExhibitions data={data} context={pageContext} {...props} />
    )}
  />
)
